import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  Heading,
  Button,
  Input,
  InputGroup,
  Textarea,
  InputLeftAddon,
  Text,
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";

import { useForm, ValidationError } from "@formspree/react";

const CtaForm = () => {
  const [state, handleSubmit] = useForm("mbjbpvbg");

  const [signedUp, setSignedUp] = useState(false);

  useEffect(() => {
    if (state.succeeded) localStorage.setItem("submitted", true);
  }, [state.succeeded]);

  useEffect(() => {
    const hasSignedUp = localStorage.getItem("submitted");
    setSignedUp(hasSignedUp);
  }, []);

  if (state.succeeded || signedUp) {
    return (
      <Box id="cta-form" textAlign="center" py={10} px={6}>
        <CheckCircleIcon boxSize={"50px"} color={"green.500"} />
        <Heading as="h2" size="xl" mt={6} mb={2}>
          Thanks for joining
        </Heading>
        <Text color={"gray.500"}>We'll get back to you soon!</Text>
      </Box>
    );
  }

  return (
    <form
      id="cta-form"
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(e);
        try {
          window.gtag_report_conversion();
        } catch (e) {}
      }}
      style={{
        height: "60vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Container
        shadow={"md"}
        rounded="md"
        bg="white"
        p={5}
        style={{
          gap: 10,
          display: "flex",
          flexDirection: "column",
        }}
        maxW="4xl"
      >
        <Heading textAlign={"center"} p={10}>
          Interested? Let us know below
        </Heading>
        <Box>
          <InputGroup>
            <InputLeftAddon children="Email" />
            <Input
              id="email"
              name="email"
              type="email"
              placeholder="user@example.com"
            />
          </InputGroup>
          <ValidationError prefix="Email" field="email" errors={state.errors} />
        </Box>
        <Box>
          <Textarea
            id="message"
            name="message"
            placeholder="Extra notes if you want to tell or ask us something (optional)"
            rows={10}
          />
          <ValidationError
            prefix="Message"
            field="message"
            errors={state.errors}
          />
        </Box>
        <Button type="submit" disabled={state.submitting}>
          Submit
        </Button>
      </Container>
    </form>
  );
};

export default CtaForm;
