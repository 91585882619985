import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Icon,
  Text,
  Stack,
  HStack,
  VStack,
} from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";

// Replace test data with your own
// const features = Array.apply(null, Array(8)).map(function (x, i) {
//   return {
//     id: i,
//     title: "Lorem ipsum dolor sit amet",
//     text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam.",
//   };
// });

const features = [
  {
    id: 0,
    title: "Drag & drop email builder",
    text: "Build beautiful email templates with our fully-featured intuitive email builder",
  },
  {
    id: 1,
    title: "HTML & CSS code editor",
    text: "Our editor supports adding your own custom HTML, alongside the cool drag and drop features",
  },
  {
    id: 2,
    title: "Testing",
    text: "Send test emails to see how your emails will look to your users",
  },
  {
    id: 3,
    title: "Clean export",
    text: "Our tool exports clean, minimal HTML without any unnecessary bloat",
  },
  {
    id: 4,
    title: "Import",
    text: "Import your existing email templates using MJML",
  },
  {
    id: 5,
    title: "Responsive",
    text: "Build emails for different screen sizes and layouts",
  },
  {
    id: 6,
    title: "Premium Templates (Beta)",
    text: "Customize our premium ready-made templates to your needs",
  },
];

export default function GridListWithHeading() {
  return (
    <Box
      id="features"
      p={4}
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Stack spacing={4} as={Container} maxW={"3xl"} textAlign={"center"}>
        <Heading fontSize={"3xl"}>Features</Heading>
        {/* <Text color={"gray.600"} fontSize={"xl"}>
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua.
        </Text> */}
      </Stack>

      <Container maxW={"6xl"} mt={10}>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={10}>
          {features.map((feature) => (
            <HStack key={feature.id} align={"top"}>
              <Box color={"green.400"} px={2}>
                <Icon as={CheckIcon} />
              </Box>
              <VStack align={"start"}>
                <Text fontWeight={600}>{feature.title}</Text>
                <Text color={"gray.600"}>{feature.text}</Text>
              </VStack>
            </HStack>
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  );
}
