import { Box, useToast } from "@chakra-ui/react";
import grapesjs from "grapesjs";
import "grapesjs-preset-newsletter";
import grapesJSMJML from "grapesjs-mjml";
import "grapesjs/dist/css/grapes.min.css";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Loading from "../components/Loading";
import { supabase } from "../supabase";
import mjmlToHtml from "mjml-browser";

import "./Editor.css";

const Editor = () => {
  const params = useParams();
  const toast = useToast();

  const [isLoading, setIsLoading] = useState(true);

  const uploadToServer = async ({ html, css, js, editor_data }) => {
    const { error } = await supabase
      .from("sites")
      .update({
        html,
        css,
        js,
        editor_data,
      })
      .match({ id: params.siteId });
    if (!error) {
      toast({
        title: "Saved",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const loadEditor = async () => {
    const { data } = await supabase
      .from("sites")
      .select("created_at, editor_data, html, css, js, id, name")
      .eq("id", params.siteId);

    const editor = grapesjs.init({
      container: "#gjs",
      plugins: [grapesJSMJML],
      storageManager: {
        type: null,
        autosave: false,
        autoload: false,
      },
      pluginsOpts: {
        [grapesJSMJML]: {},
      },
    });

    try {
      const parsed = JSON.parse(data[0].editor_data);
      editor.loadData(parsed);
    } catch (err) {}

    editor.Commands.add("upload", (editor) => {
      uploadToServer({
        html: editor.getHtml(),
        css: editor.getCss(),
        js: editor.getJs(),
        editor_data: JSON.stringify(editor.storeData()),
      });
    });

    editor.Panels.addButton("options", [
      {
        id: "save",
        className: "fa fa-floppy-o icon-blank",
        command: () => editor.runCommand("upload"),
        attributes: { title: "Save" },
      },
    ]);

    editor.Panels.addButton("options", {
      id: "export",
      className: "fa fa-download",
      command: () => {
        const mjml = editor.getHtml();
        // const css = editor.getCss();

        const { html } = mjmlToHtml(mjml);

        console.log(html);

        // console.log(mjml, css);

        // var element = document.createElement("a");
        // element.setAttribute(
        //   "href",
        //   "data:text/plain;charset=utf-8," + encodeURIComponent(text)
        // );
        // element.setAttribute("download", filename);

        // element.style.display = "none";
        // document.body.appendChild(element);

        // element.click();

        // document.body.removeChild(element);
      },
    });

    setIsLoading(false);
  };

  useEffect(() => {
    loadEditor();
  });

  if (isLoading) return <Loading height="100%" width="100%" />;

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <Box width="100%" height="100%" style={{ display: "flex" }}>
        <div id="gjs"></div>
      </Box>
    </div>
  );
};

export default Editor;
